/*
 * Font Awesome
 */

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('/assets/fonts/fa-regular-400.woff2') format("woff2");
}

.fa::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

.fa-clipboard::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content:"\f328";
}
