@use "sass:color";

$backgroundColor: #ffffff;
$bodyColor:       #222222;
$linkColor:       #b30000;
$themeColor:      #ff5050;
$secondaryColor:  #5050ff;
$navColor:        #ffffff;
$offBlack:        #36382e;
$offWhite:        #f8f8f8;

$headerFont: "Raleway", sans-serif;
$bodyFont: "Bitter", sans-serif;
$bibFont: "Courier New", monospace;

$bodyPadding:       0rem;
$elementPadding:    20px;
$navbarMaxWidth:   48rem;
$navbarMargin:    0.6rem;
$aboutMeMaxWidth:  44rem;
$contentMaxWidth:  48rem;

:focus { outline: 0 !important; }
::-moz-focus-inner { border: 0 !important; }

body {
  color: $bodyColor;
  background: $backgroundColor;
  font-family: $bodyFont;

  font-size: 16px;
  line-height: 1.45;

  -webkit-font-kerning: normal;
          font-kerning: normal;
}

/*
 * Navbar
 */

nav {
  background: rgba($navColor, 0.9);
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid rgb(218, 220, 224);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 1000;
  font-family: $headerFont;
  font-weight: 500;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.nav-wrapper {
  display: flex;
  height: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: $navbarMaxWidth;
  margin: 0 auto;
  padding: 0 1rem;
  column-gap: 2 * $navbarMargin;
}

.nav-center {
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.nav-element {
  display: flex;
  margin: 0 (-$navbarMargin);

  span { margin: 0 $navbarMargin; }
}

#nav-home {
  color: $bodyColor;
  font-size: 24px;
}

#nav-menu {
  margin-left: auto;

  ul { padding-inline-start: 0; }
}

#nav-links {
  color: $bodyColor;
  font-size: 18px;
  text-transform: lowercase;

  li { display: inline; }
  li + li::before { content: "\2022"; }
  a {
    &:link, &:visited { color: $offBlack; }
    &:focus, &:hover  { color: $themeColor; }
  }
}


/*
 * Main
 */

main { padding: 3.5rem 0; }

.centered-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem $bodyPadding;
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}


h1, h2, h3, h4, h5, h6 {
  margin-top: 3rem;
  margin-bottom: 0.8rem;
  font-family: $headerFont;
  font-weight: 500;

  @media (min-width: 600px) {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
}

h1 {
  font-size: 32px;
  @media (min-width: 600px) {
    font-size: 34px;
  }
}

h2 {
  font-size: 28px;
  @media (min-width: 600px) {
    font-size: 30px;
  }
}

.alpha-note-container {
  display: flex;
  justify-content: space-between;
}

.alpha-note-heading {
  align-self: baseline;
  flex-basis: max-content;
}

.alpha-note {
  margin-left: auto;
  padding-left: 1.5rem;
  text-align: right;
  align-self: baseline;
  flex-basis: min-content;
  flex-grow: 1;
}

.inline-block {
  display: inline-block;
}

#about-me {
  flex-basis: 30rem;
  flex-grow: 1;
  max-width: $aboutMeMaxWidth;
  padding: 0 $elementPadding;
  padding-top: 1rem;
}

.content {
  flex-grow: 1;
  max-width: $contentMaxWidth;
  padding: 0 $elementPadding;
}

a {
  text-decoration: none;
  &:link, &:visited { color: $linkColor; }
  &:focus, &:hover  { color: $themeColor; }
}

/*
 * Home
 */

img.rounded {
  object-fit: cover;
  border-radius: 50%;
  width: 225px;
  height: 225px;
  @media (min-width: 600px) {
    width: 240px;
    height: 240px;
  }
}

#headshot {
  margin: 10px auto;
}

#home-header {
  width: 16rem;
  padding: 0 $elementPadding 1rem 1.5rem;
  text-align: center;
}

#home-title {
  margin-top: 0;
  margin-bottom: 0.4rem;
  line-height: 1.2;
  letter-spacing: -0.03rem;
}

#blurb { margin: 0; }

.paper       { margin-bottom: 1rem; }
.paper-title { font-weight: bold; }
.paper-venue { font-style: italic; }
.paper-bib {
  display: none;
  color: black;
  background: $offWhite;
  font-family: $bibFont;
  font-size:    12px;
  margin-top:   10px;
  padding:      10px;
  border-radius: 4px;
  white-space: pre-wrap;

  // Select on click (https://stackoverflow.com/a/43783066)
  -webkit-user-select: all; /* Safari */
   -khtml-user-select: all; /* Konqueror HTML */
     -moz-user-select: all; /* Firefox */
      -ms-user-select: all; /* Internet Explorer/Edge */
          user-select: all; /* Chrome and Opera */

  &.show-bib { display: block; }
}
.journal-year { font-style: normal; }
.ast::before {
  content: "α";
  font-family: "KaTeX_Math";
  font-size: 80%;
  font-style: italic;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}
.special {
  &::before { 
    content: "🏆";
    font-size: 14px;
  }
  color: $secondaryColor;
}
.link {
  &::before {
    content: "[";
    color: black;
    padding-right: 1px;
  }
  &::after {
    content: "]";
    color: black;
    padding-left: 1px;
  }
}

.award { margin-bottom: 0.6rem; }

#address {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}

#address-clone {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-style: normal;
  font-size: 16px;
}

.address-button {
  color: $offBlack;
  background: #ffffff;
  height: 1.8rem;
  width: 3.8rem;
  border: 1px solid color.scale($offBlack, $lightness: 62.5%);
  border-radius: 0.4rem;
  margin-left: 0.6rem;
  padding: 0;
  font-size: 12px;
  font-family: $headerFont;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: $themeColor;
    border-color: $themeColor;
  }
  &:active {
    background: $offWhite;
    box-shadow: inset 0 0 2px $offBlack;
  }
}

// Email address obfuscation.
.addr1::before { content: attr(data-content); }
.addr2::before { content: "​"; }
.addr3::before { content: "keley.edu"; }
.addrx::before {
  content: "FILLER";
  display: inline-block;
  width: 0;
  overflow: hidden;
}
